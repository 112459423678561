<template>
    <v-dialog v-model="showDialog" max-width="600px">
        <v-card >
           <v-card-title>

            </v-card-title>
            <v-card-text class="mt-5 d-block d-sm-none">
                <div class="row">
                    <!-- <div class="col-3 col-md-3">
                        <p class="text-left mb-0 ordertitle"># Orden: </p><h5 class="orderinfo">{{order.order_id}}</h5>
                    </div> -->
                    <div class="col-6 col-md-4">
                        <p class="text-left mb-0 ordertitle"># Orden: </p><h5 class="orderinfo">{{order.order_id}}</h5>
                        <p class="text-left mb-0 ordertitle">Referencia: </p><h5 class="orderinfo">{{order.order_reference}}</h5>
                    </div>
                    <div class="col-6 col-md-4">
                         <v-btn class="mt-1 pl-5 pr-5 orderstate" outlined large color="indigo">{{order.order_state.name}}</v-btn>
                    </div>
                </div>
            </v-card-text>   
             <v-card-text>
                <div class="d-flex">
                    <v-text-field @keyup.enter="toPick" ref="inputbarcode" v-model="scan_field" class="mr-4" label="Ingrese o escanee codigo" autofocus></v-text-field>
                    <v-btn @click="toPick" class="btn-starkoms-primary">Pick</v-btn>
                    <v-btn  @click="turnOnCamera" class="btn-starkoms-primary ml-4"><v-icon >mdi-camera</v-icon></v-btn>
                </div>
                <div class="d-flex" v-if="cameraOn">
                    <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
                </div>
            </v-card-text>
            <v-card-text>
                <v-data-table
                    :items="tableData"
                    :headers="headers"
                    class="picking-table"
                    mobile-breakpoint="0"
                >   
                    <template v-slot:item.barcode="{ item}">
                            <td class="text-center">
                                {{item.barcode}}
                            </td>
                    </template>
                    <template v-slot:item.bag="{ item, index  }">
                        <td class="text-center">
                            {{Number(index) + 1}}/{{tableData.length}}
                        </td>
                    </template>
                     <template v-slot:item.done="{ item }">
                        <td class="text-center">
                            <v-icon v-if="item.state == 1" color="success">mdi-checkbox-marked-circle-outline</v-icon>
                            <v-icon v-if="item.state == 0" color="info">mdi-checkbox-blank-circle-outline</v-icon>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>

            <v-card-actions class="pb-5 d-none d-sm-block">
                 <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-2">
                        <p class="text-left mb-0 ordertitle">Bultos: </p><h5 class="orderinfo">{{tableData.length}}</h5>
                    </div>
                    <div class="col-3">
                        <v-btn class="mr-2" x-large color="danger"  @click="showDialog = false">Cancelar</v-btn>
                    </div>
                     <div class="col-5">
                        <v-btn class="mr-2" x-large color="primary" :disabled="loading || !status_order" @click="sendForm">{{order.current_action_button}}</v-btn>
                    </div>
                     
                </div>
            </v-card-actions>

            <v-card-actions class="pb-5 d-block d-sm-none">
                 <div class="row">
                    <div class="col-2 d-none d-sm-block">
                    </div>
                    <div class="col-2 col-sm-2">
                        <p class="text-left mb-0 ordertitle">Bultos: </p><h5 class="orderinfo">{{tableData.length}}</h5>
                    </div>
                    <div class="col-5 col-sm-3">
                        <v-btn class="mr-2 orderaction" color="danger"  @click="showDialog = false">Cancelar</v-btn>
                    </div>
                     <div class="col-5 col-sm-5">
                        <v-btn class="mr-2 orderaction" color="primary" :disabled="loading || !status_order" @click="sendForm">{{order.current_action_button}}</v-btn>
                    </div>
                     
                </div>
            </v-card-actions>
            <v-card-text>
                <v-divider color="gray"></v-divider>
            </v-card-text>
            
            <v-card-text class="mt-5">
                <!-- <div class="row">
                    <div class="col-12">
                        <p class="text-left mb-0 ordertitle">Punto: </p><h5 class="orderinfo">{{order.pickup_point.name}}</h5>
                    </div>
                </div> -->
                <div class=" d-none d-sm-block">
                    <div class="row">
                        <div class="col-6 col-md-3">
                            <p class="text-left mb-0 ordertitle"># Orden: </p><h5 class="orderinfo">{{order.order_id}}</h5>
                        </div>
                        <div class="col-6 col-md-4">
                            <p class="text-left mb-0 ordertitle">Referencia: </p><h5 class="orderinfo">{{order.order_reference}}</h5>
                        </div>
                        <div class="col-12 col-md-5">
                            <v-btn class="mt-1 pl-5 pr-5" outlined large color="indigo">{{order.order_state.name}}</v-btn>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-7 col-md-7">
                        <p class="text-left mb-0 ordertitle">Fecha: </p><h5 class="orderinfo">{{$moment(order.source_date).format('LLLL')}}</h5>
                    </div>
                    <div class="col-5 col-md-5">
                        <p class="text-left mb-0 ordertitle">Canal: </p><h5 class="orderinfo">{{order.source.name}}</h5>
                    </div>
                     
                </div>
                <div class="row">
                    <div class="col-7 col-md-7">
                        <p class="text-left mb-0 ordertitle">Cliente: </p><h5 class="orderinfo">{{order.customer.fullname}}</h5>
                    </div>
                    <div class="col-5 col-md-5">
                        <p class="text-left mb-0 ordertitle">DNI: </p><h5 class="orderinfo">{{order.address.dni}}</h5>
                    </div>
                     
                </div>

                <div class="row">
                    <div class="col-12 col-md-12">
                        <p class="text-left mb-0 ordertitle">Dirección: </p><h5 class="orderinfo">{{order.address.full_address}}</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 col-md-5">
                        <p class="text-left mb-0 ordertitle">Comuna: </p><h5 class="orderinfo">{{order.address.province}}</h5>
                    </div>
                    <div class="col-7 col-md-7">
                         <p class="text-left mb-0 ordertitle">Región: </p><h5 class="orderinfo">{{order.address.state}}</h5>
                    </div>
                </div>
              
             
            </v-card-text>



            



            <!-- <v-card-text class="mt-2" v-if="order.next_state.type == 'DEFAULT_IN_CUSTOMER'">
                <div class="row">
                    <div class="col-12">
                         <v-text-field v-model="customer_delivered" type="text" label="Nombre quien retira">
                        </v-text-field>
                    </div>
                    <div class="col-12">
                        <v-file-input
                        :rules="rules_file"
                        accept="image/png, image/jpeg, image/bmp"
                        label="Avatar"
                        placeholder="Pick an avatar"
                        prepend-icon="mdi-camera"
                    ></v-file-input>
                    </div>
                </div>
                  
               
            </v-card-text> -->
            
          
            

        </v-card>
            
    </v-dialog>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
     components: {
        StreamBarcodeReader
    },
    data(){
        return {
            showDialog: false,
            order: {
                order_id: null,
                order_reference: null,
                order_state: {
                    name: null
                },
                source_date: null,
                source: {
                    name: null
                },
                customer: {
                    fullname: null
                },
                address: {
                    dni: null
                }
            },              
            tableData: [],
            scan_field: undefined,
            bags: undefined,
            loading: false,
            status_order: false,
            currentProcess: null,
            customer_delivered: null,
            rules_file: [
                value => {
                return !value || !value.length || value[0].size < 2000000 || 'Avatar size should be less than 2 MB!'
                },
            ],
            cameraOn : false,
            oneCheck: false,
            
        }
    },
    created(){
        var vm = this;
        this.$parent.$on('open-dialog-pickup-last-mile', (item,currentProcess, oneCheck) => {
            // console.log(currentProcess);
            // console.log(item);
            // console.log(vm.status_order);
            
            vm.order = item;
            vm.currentProcess = currentProcess;
            console.log(vm.order.order_bag);
            vm.tableData = vm.order.order_bag;
            vm.showDialog = true;
            vm.status_order = false;
            vm.oneCheck = oneCheck;
            if (vm.oneCheck == true && vm.tableData.length == 1) {
                vm.scan_field = JSON.parse(vm.tableData[0].barcode)[0];
                
                console.log(vm.status_order); 
                vm.toPick();
                console.log(vm.status_order); 
                
               
            }

            // vm.bags = item.bags;

            // this.axios({
            //     url: 'pickup/orders',
            //     params: {
            //         page,
            //         perPage: itemsPerPage,
            //         sortBy: sortBy,
            //         sortDesc: sortDesc ? 1 : 0,
            //         search,
            //         //filterState,
            //         //filterCarrier,
            //         filterSources,
            //     },
            // }).then( response => {
            //     vm.tableData = response.data.data;
            //     vm.totalPages = response.data.last_page;
            //     vm.disabledCount = vm.tableData.filter((e) => e.disabledSelect).length;
            // }).catch( error => {
            //     console.log(error);
            // })

            // this.axios({
            //     url: 'picking/orders/'+item.id+'/order_details',
            //     method: 'GET'
            // }).then( response => {
            //     vm.order = response.data;
            //     vm.bags = vm.order.bags;
            //     vm.tableData = [];
            //     console.log(vm.order.order_details);
            //     let location = '';
            //     let image_cover = '';
            //     vm.order.order_details.forEach(element => {
            //         if (typeof element.location != "undefined") {
            //             location = element.location;
            //         }
            //         if (element.cover_image  == null) {
            //             image_cover = "https://s3.us-west-1.amazonaws.com/fullkom.oms/starkoms_placeholder.png";
            //         }
            //         else{
            //             image_cover = JSON.parse(element.cover_image).image_1000;
            //         }
                    
            //         vm.tableData.push({
            //             order_detail_id: element.id,
            //             sku: element.sku,
            //             ean: element.ean,
            //             product: element.product,
            //             required: element.quantity,
            //             picked_quantity: element.picked_quantity,
            //             done: element.picked_quantity < element.quantity ? false : true,
            //             location: location,
            //             image_cover: image_cover,
            //         });
            //     });
            //     vm.showDialog = true;
            // }).catch( error => {
            //     vm.order = undefined;
            // })
        });
    },
    methods:{
        toPick(){
            var vm = this;
            if(!this.scan_field) return;

            console.log(vm.scan_field);

            vm.tableData = vm.tableData.map((item) => {
                JSON.parse(item.barcode).forEach(element => {
                console.log(element);
                     if (element == vm.scan_field) {
                        item.state = 1;
                        vm.playSound();
                    }
                });
                console.log(item);
                
                return item;
            });

            vm.scan_field = undefined;
            this.$refs.inputbarcode.focus();
            vm.checkCompleted();
             
            // this.axios({
            //     url: 'pickup/scan',
            //     method: 'POST',
            //     data: {
            //         scan_field: this.scan_field,
            //         order_id: this.order.id,
            //     }
            // }).then( response => { // se espera: scan_field, scan_code, order_detail_id y order_completed
            //     vm.scan_field = undefined;
            //     let encontrado = vm.tableData.find(e => response.data.scan_field == e[response.data.scan_code] && !e.done && e.order_detail_id == response.data.order_detail_id);
            //     if(encontrado){
            //         encontrado.picked_quantity++;
            //         encontrado.done = encontrado.picked_quantity < encontrado.required ? false : true;
            //     }
            //     vm.checkCompleted();
            // }).catch( error => {
            //     console.log(error);
            // })
        },
        checkCompleted(){
            if( this.tableData.every(e => e.state == 1) ){
                this.status_order = true;
            }
        },
        sendForm(){
            var vm = this;
            // if(!this.bags) return;
            this.loading = true;
            this.$nextTick(() => {
                this.axios({
                    url: 'pickup/orders/'+this.order.order_id+'/state',
                    method: 'POST',
                    data: {
                        type: vm.order.next_state.type,
                    }
                }).then( response => { // se espera: order_completed
                    vm.loading = false;
                    vm.order.bags = vm.bags;
                    vm.$emit('success');
                    vm.$bvToast.toast('Bultos actualizados correctamente', {
                        title: `Notificación`,
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-center'
                    });
                    vm.showDialog = false;
                }).catch( error => {
                    vm.loading = false;
                    console.log(error);
                })
            })
                
        },
        playSound() {
            var soundurl =  '/assets/media/oms/store-scanner-beep-90395.mp3';
            console.log(soundurl);
            var audio = new Audio(soundurl);
            audio.play();
        },
        onDecode(result) {
            console.log('Barcode decoded: ' + result);
            this.scan_field = result;
            this.toPick();
        },
        turnOnCamera(){
            this.cameraOn = !this.cameraOn;
        },
        onLoaded() {
            this.cameraOn = true;
        },
    },
    computed:{
        headers(){
            return [
                // {text: '', value: 'cover_image', width: '150px' },
                // {text: 'Producto', value: 'product'},
                // {text: 'SKU', value: 'sku'},
                {text: 'Código', value: 'barcode'},
                {text: 'Bulto', value: 'bag'},
                {text: 'Completado', value: 'done'},
            ]
        }
    }
}
</script>
<style>

.scanner-container video {
    height: 150px;
    width: 100%;
    object-fit: cover;

}
.scanner-container {
    width: 100%;
    height: 150px;

}
.ordertitle{
    font-size: 14px;
}
.orderinfo{
    font-size: 18px;
}
@media screen and (max-width: 768px) {
    .ordertitle{
        font-size: 10px;
    }
    .orderinfo{
        font-size: 12px;
    }
    .orderstate{
        font-size: 12px;
        float: right;
    }
    .v-dialog{
        margin: 5px;
    }
    .v-application--is-ltr .v-data-footer__pagination {
        margin: 0;
    }
}   
</style>